<template>
  <div id="smartMenuMain" v-if="isPermission">
    <CRow id="smartMenuHeaderRow" class="mt-3">
      <CCol id="smartMenuHeaderCol" md="12" class="text-center">
        <h2 id="smartMenuMenuSetting" class="font-weight-medium text-dark">
          {{ $t('menuSetting') }}
        </h2>
        <p id="smartMenuDesSmartMenu">{{ $t('desSmartMenu') }}</p>
      </CCol>
    </CRow>
    <hr id="smartMenuDivider" />
    <CCard id="smartMenuCard">
      <CCardBody id="smartMenuCardBody">
        <CCard id="smartMenuInnerCard">
          <CCardBody id="smartMenuInnerCardBody" border-color="light">
            <table id="smartMenuTable" style="width:100%">
              <tr id="smartMenuTableRow" class="font-weight-medium h5">
                <td id="smartMenuIconCol" style="width:auto;" class="text-left d-flex">
                  <CIcon id="smartMenuIcon" name="cil-settings" size="2xl" class="text-success" />
                  <p id="smartMenuUsabilityStatus" class="my-auto">
                    {{ $t('usabilityStatus') }}
                  </p>
                </td>
                <td
                  id="smartMenudatashopIsMenuEnabled"
                  v-if="datashop.isMenuEnabled === true"
                  class="text-right text-success"
                >
                  {{ $t('enable') }}
                </td>
                <td
                  id="smartMenudatashopIsMenuDisabled"
                  v-if="datashop.isMenuEnabled === false"
                  class="text-right text-danger"
                >
                  {{ $t('disable') }}
                </td>
                <td
                  id="smartMenuSwitchEditable"
                  style="width:5%"
                  class="text-right"
                  @click="statusPopup"
                  v-if="isEditSmartMenu == true"
                >
                  <CSwitch
                    id="smartMenuSwitch"
                    v-model="datashop.isMenuEnabled"
                    color="success"
                    :value="datashop.isMenuEnabled"
                    :checked.sync="datashop.isMenuEnabled"
                  />
                </td>
                <td
                  id="smartMenuSwitchDisabled"
                  style="width:5%"
                  class="text-right"
                  v-else
                >
                  <CSwitch
                    id="smartMenuSwitchDisabledInput"
                    v-model="datashop.isMenuEnabled"
                    color="success"
                    :value="datashop.isMenuEnabled"
                    :checked.sync="datashop.isMenuEnabled"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </CCardBody>
        </CCard>
        <p id="smartMenuManageShopDes" class="mt-4">
          {{ $t('manageShopDes') }}
          <a id="smartMenuLink" :href="crmwebportal" target="_blank"> {{ $t('link') }}</a>
        </p>
        <p id="smartMenuManageProduct">
          {{ $t('manageSmartOnProduct') }}
          <router-link id="smartMenuProductManagementLink" :to="`/product`" replace>
            {{ $t('management.productManagement') }}
          </router-link>
          {{ $t('turnonSmart') }}
        </p>
        <div id="smartMenuImageRow1" class="text-center row justify-content-center">
          <img
            id="smartMenuImage1"
            src="/img/smartMenu.png"
            style="width: -webkit-fill-available;"
            class="col-md-6 col-12"
            v-if="$i18n.locale === 'th'"
          />
          <img
            id="smartMenuImage1En"
            src="/img/smartMenu.png"
            style="width: -webkit-fill-available;"
            class="col-md-6 col-12"
            v-else
          />
        </div>
        <br />
        <p id="smartMenuManageCategory">
          {{ $t('manageSmartOnCategory') }}
          <router-link
            id="smartMenuCategoryManagementLink"
            :to="`/product/category`"
            replace
          >
            {{ $t('management.categorytManagement') }}
          </router-link>
          {{ $t('turnonSmart') }}
        </p>
        <div id="smartMenuImageRow2" class="text-center row justify-content-center">
          <img
            id="smartMenuImage2"
            src="/img/smartMenu2.png"
            style="width: -webkit-fill-available;"
            class="col-md-6 col-12"
            v-if="$i18n.locale === 'th'"
          />
          <img
            id="smartMenuImage2En"
            src="/img/smartMenu2.png"
            style="width: -webkit-fill-available;"
            class="col-md-6 col-12"
            v-else
          />
        </div>
      </CCardBody>
    </CCard>
    <CModal
      id="smartMenuModal"
      :show.sync="confirmModal"
      color="success"
      centered
      headers="false"
      :title="$t('confirmUpdateInformation')"
      :footer="footer"
    >
      <template #header-wrapper class="header-modal">
        <header id="smartMenuModalHeader" class="modal-header header-modal">
          <h5 id="smartMenuModalTitle" class="modal-title font-weight-bold">
            {{ $t('confirmUpdateInformation') }}
          </h5>
          <button
            id="smartMenuModalClose"
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </header>
      </template>
      <CRow id="smartMenuModalRow" class="justify-content-center col-md-12">
        <CRow
          id="smartMenuModalRowInner"
          class="justify-content-center col-md-12"
          v-if="loading === false"
        >
          <br />
          <h4 id="smartMenuModalMessage" class="text-center" v-if="loading === false">
            {{ message }}
          </h4>
          <br />
        </CRow>
        <div
          id="smartMenuModalLoading"
          style="text-align: center"
          v-else-if="loading === true"
        >
          <CSpinner
            id="smartMenuModalSpinner"
            color="success"
            size="lg"
            class="text-success"
            centered
          />
          <br />
          <br />
          <h5 id="smartMenuModalEditing" class="text-success" v-if="isSuccess === false">
            {{ $t('editing') }}
          </h5>
          <h5 id="smartMenuModalSuccess" class="text-success" v-if="isSuccess === true">
            {{ titlesuccess }}
          </h5>
        </div>
      </CRow>
      <template #footer>
        <CRow id="smartMenuModalFooterRow" class="justify-content-center col-md-12 col-12">
          <CCol id="smartMenuSaveButtonCol" col="5" lg="4">
            <CButton
              id="smartMenuSaveButton"
              color="success"
              v-if="loading === false"
              block
              v-on:click="savedata"
            >
              {{ $t('save') }}
            </CButton>
            <CButton
              id="smartMenuSavingButton"
              color="success"
              block
              v-else-if="loading === true"
              disabled
            >
              <CSpinner id="smartMenuSavingSpinner" color="white" size="sm" /> {{ $t('save') }}
            </CButton>
          </CCol>
          <CCol id="smartMenuSpacerCol" lg="2" col="1"> </CCol>
          <CCol id="smartMenuCancelButtonCol" col="5" lg="4">
            <CButton
              id="smartMenuCancelButton"
              color="light"
              block
              @click="cancel"
            >
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import crm from '@/services/poscrm'
import permis from '@/util/permission'

export default {
  data() {
    return {
      isStatus: false,
      confirmModal: false,
      loadingButton: true,
      loading: false,
      message: '',
      footer: false,
      isSuccess: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopsetting']),
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    crmwebportal() {
      const token = `${localStorage.getItem('token')}`
      const url =
        process.env.VUE_APP_CRM_WEBPORTAL + '/manual/menu' + '?token=' + token
      return url
    },
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    isEditSmartMenu() {
      return permis.findPermissionEdit('setting', this.$route.path)
    },
  },
  methods: {
    cancel() {
      this.datashop.isMenuEnabled = !this.datashop.isMenuEnabled
      this.confirmModal = false
    },
    statusPopup() {
      if (this.datashop.isMenuEnabled) {
        this.message = this.$i18n.t('confirmDisEnable')
      } else {
        this.message = this.$i18n.t('confirmEnable')
      }
      this.confirmModal = true
    },
    savedata() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const isMenuEnabled = this.datashop.isMenuEnabled
      this.loading = true
      this.isSuccess = false
      let shops = this.shops
      for (let i = 0; i < shops.length; i++) {
        shops[i].isMenuEnabled = isMenuEnabled
        local({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/updateconfig',
          data: shops[i],
        })
          .then(() => {
            setTimeout(() => {}, 1000)
          })
          .catch(function(error) {
            console.log(error)
          })
      }
      this.$store.dispatch('getShop')
      this.generateRichMenu()
    },
    generateRichMenu() {
      const uid = this.uid
      const channelID = this.datashop.channelID
      const channelSecret = this.datashop.channelSecret
      const isLoyaltyEnabled = this.datashop.isLoyaltyEnabled
      const isMenuEnabled = this.datashop.isMenuEnabled

      const data = {
        uid: uid,
        channelID: channelID,
        channelSecret: channelSecret,
        isLoyaltyEnabled: isLoyaltyEnabled,
        isMenuEnabled: isMenuEnabled,
      }

      crm({
        method: 'post',
        url: '/api/v1.0/' + uid + '/rich-menu/generate',
        data: data,
      })
        .then(() => {
          (this.isSuccess = true),
            (this.titlesuccess = 'แก้ไขข้อมูลร้านค้าสำเร็จ')
          this.loading = false
          this.confirmModal = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
